export function getLocaleLanguageLabel(
  formatLocale?: string | undefined | null,
  targetLocale?: string | undefined | null,
  logger = console
) {
  if (!formatLocale || !targetLocale) {
    return '';
  }

  const [formatLanguage] = formatLocale.split('-');
  const targetLanguage = targetLocale;

  if (!formatLanguage || !targetLanguage) {
    return '';
  }

  try {
    const languageName = new Intl.DisplayNames([targetLanguage], {
      type: 'language'
    });

    return languageName.of(formatLanguage);
  } catch (e) {
    logger.warn(
      'could not get current language label.',
      'formatLocale',
      formatLocale,
      'targetLocale',
      targetLocale,

      e
    );
  }

  return '';
}
